import React, { useState, useRef, useEffect } from "react";
import styles from "./Select.module.css";
import { IRooms } from "@/interfaces/IHomeContent";
import { useRouter } from "next/router";
import { useGlobalContext } from "@/libs/context/GlobalContext";
import Image from "next/image";

type props = {
  selectedId?: any;
  data: IRooms[];
  selectedName: string | any[];
  type: string;
  roomId?: any;
};
export default function Select({
  selectedId,
  data,
  selectedName,
  type, roomId
}: props) {
  const router = useRouter();
  const { project: projectId = '', version: version = '' } = router.query;
  const [active, setActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { props: {latestBoqVersion,setSelectedBoqVersion,selectedBoqVersion, setIsValid, setLoading } } = useGlobalContext()

  useEffect(() => {
    // Check if required query parameters are missing
    if (type == "Versions" && !projectId) {
      setIsValid(true);
      setLoading(false)
    }
    else if (type === "Modules" && (!projectId || !version)) {
      setIsValid(true);
      setLoading(false)
    } else {
      setIsValid(false);
    }
  }, [projectId, version]);

  const handleBoq = (version: any) => {
    setSelectedBoqVersion && setSelectedBoqVersion(version)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActive(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDoqView = (e: any, boqUrl: string) => {
    e.stopPropagation()
    window.open(boqUrl, '_blank')
  }

  return (
    <div
      ref={dropdownRef}
      className={`${styles.dropDown} dropDown ${active && styles.active}`}
      onClick={() => setActive(!active)}
    >
      <div className={styles.dropdownchnRbox}>
        <span className={"font12 text000"}>{selectedName}</span>
      </div>
      {type === "Versions" && (
        <div className={styles.changEromin} style={{ left: "-57px" }}>
          {data &&
            data.map((f: any, index: number) => (
              <div
                key={f.id}
                onClick={() => handleBoq(f)}
                className={`${styles.changRoomtilesVersion} ${f.id == selectedBoqVersion.id && styles.active
                  }`}
              ><div
              >
                  <div className={styles.innerText}>
                    <span className="font10">{f.name}</span>
                    <span className="font8" style={{ fontStyle: "italic", color: "#666666" }}>{f.isActive ? '(Current Beats Unpublished )' : index === data.length - 1 ? '(Latest Beats Published)' : ''}</span>
                  </div>
                </div>
                <div className={styles.boqLink} onClick={(e) => handleDoqView(e, f.boqUrl)}>
                  <Image src="/assets/images/link.svg" width={10} height={10} alt="view" />
                </div>
              </div>
            ))}
        </div>
      )}
      {type === "Modules" && (
        <div className={styles.changEromin}>
          {data &&
            data.map((f: any) => (
              <a
                href={`${process.env.SITE_URL}/?room=${roomId}&project=${projectId}&type=${f.roomName.replace(/\s/g, '')}&version=${version}&roomname=${f.roomType}`}
                key={f.id}
              >
                <div
                  className={`${styles.changRoomtilesModular} ${selectedId.replace(/\s+/g, '') == f.roomName.replace(/\s+/g, '') && styles.active
                    }`}
                >
                  <span className="font10">{f.roomName}</span>
                </div>
              </a>
            ))}
        </div>
      )}
      <div className={active ? styles.dropDownUpArrow : styles.dropDownDownArrow}></div>
    </div>
  );
}
